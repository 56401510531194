/* ====================== PORTFOLIO ====================== */

.portfolio {
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    background-color: rgb(240, 247, 255);
    padding: 4em 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .portfolio-page-title{
    font-weight: 300;
    text-align: center;
    padding-left: 2rem;
    margin-bottom: 1em
  }
  
  
  @media (min-width: 800px) {
    .portfolio {
        display: block;
      }
  
    .portfolio-page-title{
      padding-left: 3.2rem;
    }
}

  @media (min-width: 1560px) {
    .portfolio {
        display: flex;
      }
}  