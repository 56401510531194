.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 0.3em;
}

.stack-container {
    font-size: 0.7em;
    padding: 0;
    margin: 2em 0;
    text-align: left;
    list-style: none;
    max-width: 550px;
}

.project:nth-of-type(even) {
    background-color: rgb(248, 240, 214);
}

.project-info-image-container {
    display: flex;
    flex-direction: column-reverse;
}


.project-info-container,
.project-image-container {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding-top: 3em;
}

.project-info-container{
    padding-left: 2em;
}

.project-image {
    --max-width: 400px;
    width: 100%;
    height: auto;
    max-width: var(--max-width);
    object-fit: cover;
    border-radius: 12px;
    margin: 0 auto 0.2em;
}

.project-title {
    font-size: 2.25em;
    align-self: flex-start;
    line-height: 1.1;
    position: relative;
    display: inline-block;
    z-index: 10;
}

.project-title::after {
    content: "";
    width: 100%;
    height: 15px;
    background-color: var(--color-highlight);
    display: block;
    position: absolute;
    top: 85%;
    z-index: -1;
}

.project-description {
    width: 90%;
    font-size: 1.2em;
    line-height: 1.4;
    margin-bottom: 2.5em;
    margin-right: 0;
}


.project-stack {
    background-color: var(--color-dark);
    color: var(--color-light);
    display: inline-block;
    padding: 0.3em 2em;
    margin: 0 0.6em 0.8em 0;
    border-radius: 3px;
    font-size: 1.2em;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.project-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-links {
    text-decoration: none;
    text-align: center;
    width: 150px;
    margin-bottom: 1em;
    background-color: var(--color-highlight);
    color: var(--color-light);
    padding: 0.75em 1.5em;
    letter-spacing: 1px;
    font-size: 1.1em;
    font-weight: 700;
}

.project-links:hover,
.project-links:active {
    color: var(--color-dark);
}

.project-links:hover,
.project-links:active,
.project-image:hover,
.project-image:focus {
    -webkit-box-shadow: 14px 12px 11px 3px rgba(123, 118, 118, 0.75);
    -moz-box-shadow: 14px 12px 11px 3px rgba(123, 118, 118, 0.75);
    box-shadow: 14px 12px 11px 3px rgba(123, 118, 118, 0.75);
    transition: box-shadow 400ms ease-in-out;
}


@media (min-width: 800px) {

    .project-title {
        align-self: flex-start;
    }

    .project-title::after {
        width: 50%;
        top: 70%;
        left: -5px;
    }

    .project-info-image-container {
        flex-direction: row;
        justify-content: space-around;
    }

    .project-info-container,
    .project-image-container {
        max-width: 650px;
    }

    .project-image-container {
        /* width: 45%; */
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        padding-top: 2em;
    }

    .project-image {
        --max-width: 500px;
    }

    .project-links-container {
        width: 90%;
        align-self: center;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 1em;
    }

}