
/* ====================== ABOUT ====================== */
.about-flex-container {
  display: flex;
  flex-direction: column-reverse;
}

.about-body {
  margin-bottom: 1.5em;
  max-width: 550px;
}

.cv-text {
  font-size: 1.5em;
  padding: 1em 0 2em;
}

.about-highlight a {
  color: var(--color-highlight);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

.about-highlight a:active,
.about-highlight a:hover {
  color: var(--color-dark);
  text-decoration: underline currentColor;
}

.profile-image {
  width: 90%;
  max-width: 200px;
}


.tech-stack-list-item {
  display: inline-block;
  margin: 0 1.5em 0.8em 0;
  background-color: transparent;
  color: var(--color-dark);
}

.stack-image-container {
  width: 35px;
  margin: 0 auto;
}

.stack-image {
  width: 100%;
}

.skill-name {
  color: var(--color-dark);
  background-color: transparent;
  padding: 0 1em;
}

.about-me-body {
  padding-bottom: 1em;
}

.about-me-link {
  display: block;
  text-decoration: none;
  margin-top: 1em;

  color: var(--color-highlight);
  font-weight: bolder;

}

/* ================ MEDIA QUERIES ================= */

@media (min-width: 800px) {
  .profile-image {
    width: 250px;
    object-fit: contain;
  }

  /* .stack-container {
    max-width: 550px;
  } */

  .about-me-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .about-me-info {
    max-width: 550px;
  }
 
  .about-flex-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .about-body-container {
    width: 50%;
  }

  .cv-container{
    width: 38%;
  }

  .cv-text {
    padding: 3em 0 2em 2em;
  }
}