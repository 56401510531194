/* ================= Navigation =================== */

.header {
  width: 100%;
  position: fixed;
  z-index: 950;
  background-color: var(--color-dark-2);
  height: 75px;
  display: flex;
  place-content: center;
  border-bottom: 1px solid var(--color-highlight);
}

.header-content-wrapper {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  position: fixed;
  top: 75px;
  bottom: 50%;
  right: 0;
  left: 60%;
  z-index: 900;
  background: var(--color-dark);
  transform: translateY(-140%);
  opacity: 0;
  transition: opacity 500ms ease;
}

.nav-logo {
  width: 30px;
  height: 30px;
  margin-left: 1em;
  padding: 0;
}

.sticky .header {
  border-bottom: 3px solid var(--color-highlight);
  /* background-color: var(--color-dark-2); */
  -webkit-box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
  -moz-box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
  box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
}

.logo-nav {
  width: 100%;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.nav-list {
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.nav-link {
  color: var(--color-light);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 700;
}

/* .sticky .nav-link {
  color: var(--color-light);
} */


/* .active {
  color: var(--color-highlight);
} */

.menu-icon {
  width: 2rem;
  height: 20px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  position: relative;
  right: 1em;
  z-index: 1000;
}

.nav-open .menu-icon {
  right: 2em;
}

.hamburger {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.hamburger::after,
.hamburger::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;

}

.hamburger,
.hamburger::after,
.hamburger::before {
  background-color: var(--color-light);
  height: 4px;
  width: 2rem;
  border-radius: .5em;
  transition: transform 250ms ease-in-out;
}

.sticky .hamburger,
.sticky .hamburger::after,
.sticky .hamburger::before {
  background-color: var(--color-light);
}


.nav-open .hamburger,
.nav-open .hamburger::after,
.nav-open .hamburger::before {
  background-color: var(--color-light);
}

.hamburger::after {
  top: 8px;
}

.hamburger::before {
  bottom: 8px;
}



.nav-open .nav {
  transform: translateY(0);
  opacity: 1;
}

.nav-open .menu-icon {
  position: fixed;
}

.nav-open .hamburger {
  transform: rotate(135deg)
}

.nav-open .hamburger::before {
  opacity: 0;
}

.nav-open .hamburger::after {
  transform: rotate(90deg) translateX(-8px)
}

.nav-open .nav-list {
  padding: 0;
  margin: 0;
}

.nav-list li a:hover,
.nav-open .nav-list li a:active{
  text-decoration: 3px underline var(--color-highlight);
  text-underline-offset: 8px;
  color: var(--color-highlight);
}


/* =============== FOOTER =============== */


.footer {
  width: 100%;
  overflow: hidden;
  background-color: var(--color-dark-2);
  color: var(--color-light);
  padding: 2em 0 0;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: 2px;
}

.footer-nav-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

.footer-nav-links {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-light);
  padding: 2em 1em;
}

.footer-social-media-container {
  padding: 2em 0;
}

.footer-nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}

.footer-nav-list>li {
  padding: 1em;
  display: inline;
  width: 50%;

}

.footer-nav-list>li:last-of-type {
  padding-bottom: 0;
}



.footer-social-media-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1em;
}

.footer-social-media-text {
  font-size: 1.3em;
  padding: 0 0 0 2em;
}

.footer-nav-list-icons {
  display: flex;
  padding: 0;
  place-content: center;
  list-style: none;

}

.footer-nav-list-icons>li {
  padding: 0 2em;
}

/* .footer .active {
  color: var(--color-highlight);
} */

.copyright {
  color: var(--color-light);
  padding: 2em 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5em;

}

.copyright-info {
  padding: 0 1em 1em;


}

.footer-logo-container {
  width: 25%;
  max-width: 40px;
  padding: 0.3em;
}

.footer-logo {
  width: 100%;
  object-fit: contain;
}

.footer-nav-link {
  color: var(--color-light);
  text-decoration: none;
  text-align: left;
  font-weight: 700;
}

.footer-nav-link:hover,
.footer-nav-link:focus {
  color: var(--color-light);
  text-decoration: none;
}

.footer-social-icon {
  width: 20px;
}

/* ============ MEDIA QUERIES ============ */

@media (min-width: 1100px) {
  .hero-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
  }
}

@media (min-width: 800px) {

  .header {
    align-items: center;
  }

  .menu-icon {
    display: none;
  }

  .nav {
    background: transparent;
    position: static;
    transform: translateX(0%);
    transition: 300ms ease-in-out;
    padding: 0 3em 0 1em;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 1;
  }

  .nav-logo {
    margin-left: 3em;
  }

  .nav-list {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0;
  }


  .nav-link {
    color: var(--color-light);
    text-decoration: none;
    font-weight: 700;
    padding: 0 1em;

    text-decoration: none;
    text-underline-offset: 8px;
  }

  .nav-link:hover {
    /* color: var(--color-highlight); */
    text-decoration: 3px double var(--color-highlight);
  }

  .footer {
    font-size: 0.7em;
  }

  .footer-nav-container {
    flex-direction: row;
    justify-content: space-around;
    padding: 1em 0 3em;
  }

  .footer-nav-links {
    width: 33%;
    border: none;
  }

  .footer-social-media-text {
    width: 60%;
  }

}