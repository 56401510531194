.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.form-container {
    padding: 0;
    max-width: 500px;
    margin: 0 auto;
}

.form--display-container{
    align-self: center;
}

.form--display-container h3{
    text-align: center;
}

.form {
    width: 100%;
}

.form-page-title {
    font-size: 2em;
}

.form-info {
    margin-bottom: 2em;
}

.form-info:first-of-type {
    margin: 1em 0 0.3em;
}

.input-container {
    margin-bottom: 1em;
}

.input-container:last-of-type {
    margin: 2.5em 0;
}

.form-input {
    width: 100%;

    border: none;
    border-radius: 40px;
}

.form-input {
    font-family: "Inter", sans-serif;
    margin-bottom: 0.2em;
    padding: 0.8em 1.3em;
    background-color: var(--color-dark);
    color: var(--color-light);
}

.message {
    min-height: 200px;
    width: 100%;
    border-radius: 10px;

}

::placeholder {
    color: rgba(241, 253, 255, 0.6);
}

.contact-form-btn,
.form-btn-submit {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    font-weight: 700;
    padding: 1em 2em;
    border: none;
    border-radius: 6px;
    color: var(--color-light);
    cursor: pointer;
}

.contact-form-btn {
    display: block;
    background-color: var(--color-dark);
    width: 150px;
    margin: 2em auto;

}

.form-btn-submit {
    background-color: var(--color-highlight);
    margin: 0 0 1.5em;
}

.contact-form-btn:hover,
.contact-form-btn:focus,
.form-btn-submit:hover,
.form-btn-submit:focus {
    -webkit-box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
    -moz-box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
    box-shadow: 6px 19px 17px -15px rgba(38, 38, 38, 0.67);
}

.form-btn-submit:hover,
.form-btn-submit:focus {
    background-color: var(--color-dark);
    outline: none;

}

.error-message{
    color: rgb(255, 0, 0);
    font-size: 0.7em;
    padding: 0 1em;
}